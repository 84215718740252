import { NextPage } from "next";
import { NextSeo } from "next-seo";
import { Headline, Logo, Paragraph, Section } from "ui";

export const NotFoundModule: NextPage = () => {
	return (
		<>
			<NextSeo title={"404"} />
			<Section fullHeight={true} verticalAlignment={"center"} horizontalAlignment={"center"}>
				<Logo />
				<Headline level={1}>404</Headline>
				<Paragraph>sorry...</Paragraph>
			</Section>
		</>
	);
};
